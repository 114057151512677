<template>
  <div class="contact-us main-wrapper">
    <img :src="image.url">
    <!-- <div class="first-title">联系我们</div>
    <img src="../assets/images/contact_us/1.webp" />
    <div class="flex-wrapper">
      <span>邮箱：lzfu@@harrenature.com</span>
      <span>电话：19521586868</span>
      <span>地址：浙江华睿生物技术有限公司 (吴兴区中天二路23号)</span>
    </div>
    <div class="second-title">原料业务</div>
    <div class="flex-wrapper">
      <span>浙江华睿生物技术有限公司BTB事业部销售总监 张洪朋</span>
      <span>电话：18800359267</span>
      <span>邮箱：hpzhang@harrenature.com</span>
    </div>
    <div class="seperator"></div> -->
  </div>
</template>

<script>
import { getCosUrl } from '../utils';
export default {
  name: "contact-us",
  data(){
    return {
        image: {
            key: "/contact-us/1.webp",
            url: ""
        }
    }
  },
  watch: {
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.image.url = getCosUrl(this.image.key);
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.image.url = getCosUrl(this.image.key);
            }
        },
        deep: true
    }
  },
  mounted() {
    this.image.url = getCosUrl(this.image.key);
  },
};
</script>

<style lang="less">
div.contact-us {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   img {
//     width: 120rem !important;
//     // margin-left: 18.75%;
//     margin-bottom: 3.2rem;
//   }
//   div.flex-wrapper {
//     justify-content: space-between;
//     // margin-left: 18.75%;
//     font-size: 1.8rem;
//     color: #000;
//     display: flex;
//     width: 120rem;
//   }
}
</style>
